
.admin-item-link {
  cursor: pointer;

  .uk-card-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .uk-icon {
      position: relative;
      margin-right: 4px;
      // top: 0px;
    }
  }
}
